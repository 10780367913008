<template>
    <b-overlay :show="showLoader">
        <b-card class="my-1 d-flex align-center" :title="$t('menu.subcategory.price_lists')" v-if="firstLoadDone">
            <b-row>
                <!-- Izbira poslovalnice-->
                <b-col cols="12" md="6" >
                    <b-form-group v-if="businessUnits && businessUnits.length > 0" :label="$t('general.business_unit')">
                        <v-select v-model="businessUnit" label="name" :options="businessUnits" @input="changeSelectBusiness" :clearable="false"/>
                    </b-form-group>
                </b-col>
 
                <!-- Izbira cenika-->
                <b-col cols="12" md="6">
                    <b-form-group v-if="priceLists && priceLists.length > 0" :label="$t('general.price_list')">
                        <v-select :disabled="!(priceList && priceList.id !== $NilObjectId)" v-model="priceList" label="name" :options="priceLists" @input="changeSelectPriceList" :clearable="false"/>
                    </b-form-group>
                    <div v-else>
                        <b-alert v-if="$hasPermission($permissions.ProductsWrite) && $hasPermission($permissions.PricesWrite)" show variant="warning" class="p-1 mt-2"> <!-- vem da načeloma ne bi smel do tega prit zarad default price lista sam useen-->
                            {{$t('keys.price_lists.no_price_lists_in_business_unit')}} <i>{{$t('keys.price_lists.add_price_list')}}</i>.
                        </b-alert>
                    </div>
                </b-col>
                
                <!-- Opis cenika-->
                <b-col cols="12" md="6">
                    <div v-if="priceList && !priceList.default">
                        <h4>
                            <span class="font-weight-bold text-primary" v-if="priceList.valid_conditions.valid_infinite">
                                {{$t('keys.price_lists.valid_infinite')}}
                            </span>
                            <span v-else>
                                <span class="font-weight-normal">{{$t('keys.valid_from')}}</span>
                                <span class="font-weight-bold text-primary"> {{dayjs(priceList.valid_conditions.valid_from).format('DD.MM.YYYY')}}</span>
                                <span class="font-weight-normal"> {{$t('keys.until')}}</span>
                                <span class="font-weight-bold text-primary"> {{dayjs(priceList.valid_conditions.valid_to).format('DD.MM.YYYY')}}</span>
                            </span>
                            <span v-if="checkTime">
                                <span class="font-weight-normal"> {{$t('keys.between')}}</span>
                                <span class="font-weight-bold text-primary"> {{dayjs(priceList.valid_conditions.start_hour).utc().format('HH:mm')}}</span>
                                <span class="font-weight-normal"> {{$t('keys.and')}}</span>
                                <span class="font-weight-bold text-primary"> {{dayjs(priceList.valid_conditions.end_hour).utc().format('HH:mm')}}</span>
                                <span class="font-weight-normal"> {{$t('keys.hour')}}</span>
                                <span class="font-weight-normal">.</span>
                            </span>
                            <span v-else-if="!priceList.valid_conditions.valid_infinite">
                                <span class="font-weight-normal">.</span>
                            </span>
                        </h4>
                        <div v-if="priceList.valid_conditions.interval === 'monthly'" class="d-flex align-items-center">
                            <h4 class="font-weight-normal">{{$t('keys.valid_on_days')}}</h4>
                            <div class="ml-1 d-flex flex-wrap" style="gap: 1rem;">
                                <h4 v-for="item of priceList.valid_conditions.days" :key="'day_'+item">
                                    <b-badge variant="primary">{{daysOptions.find(x => x.value === item).text}}.</b-badge>
                                </h4>
                            </div>
                            <h4 class="ml-1 font-weight-normal">
                                {{$t('keys.day_in_month')}}.
                            </h4>
                        </div>
                        <div v-else-if="priceList.valid_conditions.interval === 'weekly'" class="d-flex align-items-center">
                            <h4 class="font-weight-normal">{{$t('keys.valid_on_days')}}:</h4>
                            <div class="ml-1 d-flex flex-wrap" style="gap: 1rem;">
                                <h4 v-for="item of priceList.valid_conditions.weekdays" :key="'day_'+item">
                                    <b-badge variant="primary">{{weekdays.find(x => x.value === item).textFull}}</b-badge>
                                </h4>
                            </div>
                        </div>
                        <h4 class="font-weight-normal">
                            {{$t('keys.priority')}}: <span class="font-weight-bold text-primary"> {{priceList.priority}} </span>
                        </h4>
                    </div>
                </b-col>
    
                <!-- Gumbi-->
                <b-col lg:cols="6" class="d-flex flex-wrap align-items-start" v-if="$hasPermission($permissions.ProductsWrite)">
                    <div class="d-flex w-100" :class="[
                        {'justify-content-md-between': priceList},
                        {'justify-content-md-end': !priceList},]">
                        <div v-if="priceList && !priceList.default" class="d-flex align-items-end">
                            <b-form-checkbox :switch="true" v-model="priceList.active" @change="updateActive">
                                <h4 class="my-auto">
                                    <b-badge v-if="priceList.active" variant="success" size="md">{{$t('keys.price_lists.price_list_active')}}</b-badge>
                                    <b-badge v-else variant="danger" size="lg">{{$t('keys.price_lists.price_list_inactive')}}</b-badge>
                                </h4>
                            </b-form-checkbox>
                        </div>
                        
                        <div class="d-flex" style="gap: 0.8rem;">
                            <b-button v-if="priceList && !priceList.default" variant="danger" @click="removePriceList">
                                <feather-icon icon="TrashIcon"/>
                            </b-button>
                            <b-button v-if="priceList && !priceList.default" variant="secondary" @click="editPriceList">
                                <feather-icon icon="EditIcon"/>
                            </b-button>
                            <b-button v-if="(!priceList || priceList && priceList.id !== $NilObjectId) && $hasPermission($permissions.ProductsWrite) && $hasPermission($permissions.PricesWrite)" variant="primary" @click="$refs.addModal.open()">
                                <feather-icon icon="PlusSquareIcon"/>
                                {{ $t('keys.price_lists.add_price_list') }}
                            </b-button>
                        </div>
                    </div>
                </b-col>
            </b-row>

            <b-alert v-if="priceList && priceList.default" variant="warning" show class="p-1 mt-2">
                {{$t('keys.price_lists.no_edit_price_list')}}
            </b-alert>

            <div class="d-flex align-items-center justify-content-between mt-2">
                <h2 class="m-0">{{$t('keys.products.products')}}</h2>
                <b-button v-if="priceList && !priceList.default && $hasPermission($permissions.ProductsWrite)" variant="primary" @click="editProducts">
                    <feather-icon icon="EditIcon"/>
                    {{ $t('keys.edit_products') }}
                </b-button>
            </div>

            <Table class="mt-2" :columnDefs="columnDefs" :rowData="priceListProducts" @deleteRow="removeProduct" @edit="edit"/>
        </b-card>
        
        <div v-if="businessUnit">
            <AddPriceList ref="addModal" :businessUnitId="businessUnit.id" @itemAdded="itemAdded" @edit="onEditPriceList"/>
        </div>
        
        <AddProductsToPriceList ref="productModal" :productSubcategories="productSubcategories" :products="products" :priceListProducts="priceListProducts" v-on:itemAdded="loadPriceListProducts"/>
    </b-overlay>
</template>

<script>
    import Table from '@/views/components/Table/Table'
    import {BButton, BCard, BCol, BOverlay, BRow, BBadge, BFormCheckbox, BAlert, BFormGroup} from 'bootstrap-vue'
    import vSelect from 'vue-select'
    import AddPriceList from '@/views/PriceList/AddPriceList'
    import AddProductsToPriceList from '@/views/PriceList/AddProductsToPriceList'
    import * as Sentry from '@sentry/vue'
    import {Tiers} from '@/libs/enums/Tiers'
    import { getWeekdays, getDaysOptions} from '@/libs/constants/price-lists.js'


    export default {
        components: {
            AddProductsToPriceList,
            AddPriceList,
            Table,
            BRow,
            BCol,
            BCard,
            BOverlay,
            BButton,
            BBadge,
            BFormCheckbox,
            BAlert,
            vSelect,
            BFormGroup
        },
        data() {
            return {
                productSubcategories: [],
                products: [],
                priceListProducts: [],
                priceLists: [],
                businessUnits: [],
                businessUnit: [],
                priceList: null,
                showLoader: false,
                firstLoad: false,
                firstLoadDone: false,
                isEditPriceList: false
            }
        },
        methods: {
            async itemAdded(id) {
                await this.loadData()

                if (this.priceLists && this.priceLists.length > 0) {
                    const tmp = this.priceLists.find(ele => ele.id === id)

                    if (tmp) {
                        this.priceList = tmp
                    } else {
                        this.priceList = this.priceLists[0]
                    }

                    this.changeSelectPriceList()
                }

            },
            async loadData() {
                try {
                    this.showLoader = true

                    const responseBusiness = await this.$http.get('/api/client/v1/business_units/')
                    this.businessUnits = responseBusiness.data ?? []
                    this.businessUnits.unshift(this.givenekaj)

                    if ((!this.businessUnit || !this.businessUnit.id) && this.businessUnits.length > 0) {
                        this.businessUnit = this.businessUnits[0]
                    }

                    const response1 = this.$http.get(`/api/client/v1/price_lists/business_units/${this.businessUnit.id}`)
                    const response2 = this.$http.get('/api/client/v1/products/')
                    const response3 = this.$http.get('/api/client/v1/product_subcategories/')


                    const responses = await Promise.all([response1, response2, response3])
                    this.priceLists = responses[0].data ?? []
                    this.products = responses[1].data ?? []
                    this.products = this.products.filter(product => product.disabled !== true)
                    this.productSubcategories = responses[2].data ?? []

                    if (this.businessUnit.id === this.$NilObjectId) {
                        this.priceLists.unshift({name: 'Osnovni cenik', id: this.$NilObjectId, default: true})
                        this.priceList = this.priceLists[0]
                    }

                    if (this.priceLists.length > 0) {
                        if (this.isEditPriceList) {
                            this.priceList = this.priceLists.find(x => x.id === this.priceList.id)
                            this.isEditPriceList = false
                        } else {
                            this.priceList = this.priceLists[0]
                        }

                        const response4 = await this.$http.get(`/api/client/v1/price_lists/items/${this.priceList.id}`)
                        this.priceListProducts = response4.data ?? []
                    } else {
                        this.priceList = null
                    }
                    this.firstLoadDone = true
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_load_data'))
                } finally {
                    this.showLoader = false
                }
            },
            async edit(params) {
                try {
                    this.showLoader = true
                    await this.$http.post(`/api/client/v1/price_lists/items/${this.priceList.id}/${params.data.item_id}`, params.data)
                    this.$printSuccess(this.$t('print.success.edit'))
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_edit'))
                } finally {
                    this.showLoader = false
                    await this.loadPriceListProducts()
                }
            },
            async remove(id) {
                try {
                    const confirmDelete = await this.$confirmDialog(this.$t('keys.price_lists.confirm.delete_price_list'))
                    if (!confirmDelete) return
                    await this.$http.delete(`/api/client/v1/price_lists/${id}`)
                    this.$printSuccess(this.$t('print.success.delete'))
                    this.firstLoad = false
                    await this.loadData()
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_delete'))
                }
            },
            async removeProduct(id) {
                try {
                    const confirmDelete = await this.$confirmDialog(this.$t('print.confirm.delete_row'))
                    if (!confirmDelete) return
                    await this.$http.delete(`/api/client/v1/price_lists/items/${this.priceList.id}/${id}`)
                    this.$printSuccess(this.$t('print.success.delete'))
                    await this.loadPriceListProducts()
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_delete'))
                }
            },
            editPriceList() {
                if (this.priceList) this.$refs.addModal.open(this.priceList)
            },
            editProducts() {
                if (this.priceList) this.$refs.productModal.open(this.priceList)
            },
            removePriceList() {
                if (this.priceList) this.remove(this.priceList.id)
            },
            async loadPriceListProducts() {
                try {
                    this.showLoader = true

                    const response = await this.$http.get(`/api/client/v1/price_lists/items/${this.priceList.id}`)
                    this.priceListProducts = response.data ?? []
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_load_data'))
                } finally {
                    this.showLoader = false
                }
            },
            async updateActive() {
                try {
                    this.showLoader = true

                    await this.$http.post(`/api/client/v1/price_lists/${this.priceList.id}`, this.priceList)
                    this.$printSuccess(this.$t('print.success.edit'))
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_edit'))
                    this.priceList.active = !this.priceList.active
                } finally {
                    this.showLoader = false
                }
            },
            async changeSelectPriceList() {
                try {
                    this.showLoader = true

                    const response = await this.$http.get(`/api/client/v1/price_lists/items/${this.priceList.id}`)
                    this.priceListProducts = response.data ?? []
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_load_data'))
                } finally {
                    this.showLoader = false
                }
            },
            async changeSelectBusiness() {
                try {
                    this.showLoader = true

                    const response = await this.$http.get(`/api/client/v1/price_lists/business_units/${this.businessUnit.id}`)
                    this.priceLists = response.data ?? []

                    if (this.businessUnit.id === this.$NilObjectId) {
                        this.priceLists.unshift({name: 'Osnovni cenik', id: this.$NilObjectId, default: true})
                    }

                    this.priceList =  null

                    if (this.priceLists && this.priceLists.length > 0) {
                        this.priceList = this.priceLists[0]
                        this.loadPriceListProducts()
                    } else {
                        this.priceListProducts = []
                    }

                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_load_data'))
                } finally {
                    this.showLoader = false
                }
            },
            onEditPriceList() {
                this.isEditPriceList = true
            },
            getCategoryName(value) {
                if (value) {
                    const item = this.productSubcategories.find(x => x.id === value)
                    if (item) return item.name
                }
                return '/'
            }
        },
        computed: {
            givenekaj() {
                return {name: this.$t('general.continue'), id: this.$NilObjectId}
            },
            weekdays() {
                return getWeekdays()
            },
            daysOptions() {
                return getDaysOptions()
            },
            Tiers() {
                return Tiers
            },
            editRow() {
                return (this.priceList && this.priceList.default)
            },
            checkTime() {
                if (this.priceList.valid_conditions.start_hour.substring(11, 19) === '00:00:00') {
                    if (this.priceList.valid_conditions.end_hour.substring(11, 19) === '23:59:59') {
                        return false
                    }
                }
                return true
            },
            columnDefs() {
                const hasPermissions = this.$hasPermission(this.$permissions.PricesWrite)

                const defs = [
                    { headerName: this.$t('table_fields.name'), field: 'name', filter: true, editable: false},
                    { headerName: this.$t('table_fields.subcategory'), field: 'product_subcategory', editable:false, filter: true, floatingFilterComponentParams: () => { return  {type: 'select', values: this.productSubcategories} }, cellEditorFramework: 'selectEditor', cellEditorParams: () => { return {values: this.productSubcategories} }, cellRenderer: (params) => this.getCategoryName(params.value)},
                    { headerName: this.$t('table_fields.price'), editable: hasPermissions, field: 'price', filter: true, filterParams: {textCustomComparator: this.$amountFilter}, cellEditorFramework: 'priceAndPercentageEditor', cellRenderer: 'DisplayPrice'}
                ]

                if (hasPermissions) {
                    defs.push(
                        { headerName: this.$t('table_fields.actions'), editable: false, filter: false, sortable: false, colId:'actions', cellRenderer: 'btnCellRenderer', cellRendererParams: () => { return {button: 'priceList', noDelete: this.editRow} } }
                    )
                }

                return defs
            }
        },
        mounted() {
            this.loadData()
        }
    }
</script>

<style scoped>

</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>